// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-acerca-de-jsx": () => import("./../../../src/pages/acerca-de.jsx" /* webpackChunkName: "component---src-pages-acerca-de-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-posts-list-jsx": () => import("./../../../src/templates/PostsList.jsx" /* webpackChunkName: "component---src-templates-posts-list-jsx" */),
  "component---src-templates-single-post-jsx": () => import("./../../../src/templates/SinglePost.jsx" /* webpackChunkName: "component---src-templates-single-post-jsx" */),
  "component---src-templates-tag-posts-jsx": () => import("./../../../src/templates/TagPosts.jsx" /* webpackChunkName: "component---src-templates-tag-posts-jsx" */),
  "component---src-templates-tags-page-jsx": () => import("./../../../src/templates/TagsPage.jsx" /* webpackChunkName: "component---src-templates-tags-page-jsx" */)
}

